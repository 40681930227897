import React, { useCallback, useEffect, useRef } from "react"
import { TouchableOpacity, View, ViewProps, Dimensions } from "react-native";
import LinearGradient from 'react-native-web-linear-gradient';
import { shallow } from "zustand/shallow";
import { 
    Map,
    Path,
    Paths, 
    Poi,
    Pois,
    Aoi,
    SnowmobileSubPath,
    SnowmobileSubPaths,
    ThemedText,
    LoadingSpinner,
    FeatureType,
    MapType,
    PathCategoryId,
    useThemedStyleFunction,
    withExternalStyle,
    LanguageSelector
} from "@kullaberg/shared";
import { useDataStore, useStateStore } from "../../stores";
import { mapPanelStyle } from "./map-panel.style";
import { SMALL_SCREEN_WIDTH } from "../../constants"; 

const MapPanel = (props: ViewProps) => {
    const {style: styleProp} = props;
    const mapRef = useRef<any>();
    // Get data from the stores
    const {
        paths,
        pathGpsCoordinates,
        pathCategories,
        aois,
        aoiCategories,
        aoiGpsCoordinates,
        pois,
        poiCategories,
        snowmobilePaths,
        snowmobileSubPaths,
        mainContent,
        additionalMapContent,
    } = useDataStore(state => state, shallow);
    const {
        selectedType,
        selectedId,
        setSelected,
        filters,
        focusOnMainContent,
        sideBarOpen
    } = useStateStore(state => state, shallow);

    // Add values to map once they get assigned
    useEffect(() => {
        if (paths || pois || snowmobilePaths || snowmobileSubPaths || aois) {
            const newFocus = [];

            if (paths) {
                // Check main content and additional content whether certain paths should be displayed
                const filteredPaths = Object.values(paths ?? {})
                    .filter((path: Path) => mainContent.pathCategories?.length === 0
                        || additionalMapContent?.pathCategories?.length === 0
                        || mainContent.pathCategories?.includes(path.pathCategoryId)
                        || additionalMapContent?.pathCategories?.includes(path.pathCategoryId));

                // Convert back to KVP
                const fileteredPathsObj = filteredPaths.reduce((obj, current) => {
                    obj[current.id] = current;
                    return obj;
                }, {} as Paths);

                mapRef.current.setPaths(fileteredPathsObj, pathCategories, pathGpsCoordinates);

                // Add paths to our default focus
                if (focusOnMainContent) {
                    const focusPathIds = filteredPaths
                        .filter((path: Path) => mainContent.pathCategories?.length === 0 || mainContent.pathCategories?.includes(path.pathCategoryId))
                        .map((path: Path) => path.id);

                    if (focusPathIds.length) {
                        newFocus.push({type: FeatureType.Path, ids: focusPathIds});
                    }
                }
                else {
                    newFocus.push({type: FeatureType.Path, ids: filteredPaths.map((path: Path) => path.id)});
                }
            }
            if (aois) {
                // Check main content and additional content whether certain aois should be displayed
                const filteredAois = Object.values(aois ?? {})
                    .filter((aois: Aoi) => mainContent.aoiCategories?.length === 0
                        || additionalMapContent?.aoiCategories?.length === 0
                        || mainContent.aoiCategories?.includes(aois.aoiCategoryId)
                        || additionalMapContent?.aoiCategories?.includes(aois.aoiCategoryId));

                // Convert back to KVP
                const fileteredAoiObj = filteredAois.reduce((obj, current) => {
                    //@ts-ignore
                    obj[current.id] = current;
                    return obj;
                }, {} as Aoi);

                mapRef.current.setAois(fileteredAoiObj, aoiCategories, aoiGpsCoordinates);

                // Add aois to our default focus
                if (focusOnMainContent) {
                    const focusAoiIds = filteredAois
                        .filter((aoi: Aoi) => mainContent.aoiCategories?.length === 0 || mainContent.aoiCategories?.includes(aoi.aoiCategoryId))
                        .map((aoi: Aoi) => aoi.id);
                    if (focusAoiIds.length) {
                        newFocus.push({type: FeatureType.Aoi, ids: focusAoiIds});
                    }
                }
                else {
                    newFocus.push({type: FeatureType.Aoi, ids: filteredAois.map((aoi: Aoi) => aoi.id)});
                }
            }
            if (pois) {
                // Check main content and additional content whether certain pois should be displayed
                const filteredPois = Object.values(pois ?? {})
                    .filter((poi: Poi) => mainContent.poiCategories?.length === 0
                        || additionalMapContent?.poiCategories?.length === 0
                        || mainContent.poiCategories?.includes(poi.poiCategoryId)
                        || additionalMapContent?.poiCategories?.includes(poi.poiCategoryId));

                // Convert back to KVP
                const filteredPoisObj = filteredPois.reduce((obj, current) => {
                    obj[current.id] = current;
                    return obj;
                }, {} as Pois);

                mapRef.current.setPois(filteredPoisObj, poiCategories);

                // Add pois to our default focus
                if (focusOnMainContent) {
                    const focusPoiIds = filteredPois
                        .filter((poi: Poi) => mainContent.poiCategories?.length === 0 || mainContent.poiCategories?.includes(poi.poiCategoryId))
                        .map((poi: Poi) => poi.id);

                    if (focusPoiIds.length) {
                        newFocus.push({type: FeatureType.Poi, ids: focusPoiIds});
                    }
                }
                else {
                    newFocus.push({type: FeatureType.Poi, ids: filteredPois.map((poi: Poi) => poi.id)});
                }
            }
            if (snowmobilePaths && snowmobileSubPaths) {
                // Check main content and additional content whether certain snowmobile subpaths should be displayed
                const filteredSnowmobileSubPaths = Object.values(snowmobileSubPaths ?? {})
                    .filter((subPath: SnowmobileSubPath) => mainContent.pathCategories?.includes(PathCategoryId.Snowmobiling)
                        || additionalMapContent?.pathCategories?.includes(PathCategoryId.Snowmobiling));

                // Convert back to KVP
                const filteredSnowmobileSubPathsObj = filteredSnowmobileSubPaths.reduce((obj, current) => {
                    obj[current.id] = current;
                    return obj;
                }, {} as SnowmobileSubPaths);

                mapRef.current.setSnowmobilePaths(snowmobilePaths, filteredSnowmobileSubPathsObj);

                // Add snowmobile subpaths to our default focus
                if (focusOnMainContent) {
                    const focusSnowmobileSubPathIds = filteredSnowmobileSubPaths
                        .filter((subPath: SnowmobileSubPath) => mainContent.pathCategories?.includes(PathCategoryId.Snowmobiling))
                        .map((subPath: SnowmobileSubPath) => subPath.id);

                    if (focusSnowmobileSubPathIds) {
                        newFocus.push({type: FeatureType.SnowmobileSubPath, ids: focusSnowmobileSubPathIds});
                    }
                }
                else {
                    newFocus.push({type: FeatureType.SnowmobileSubPath, ids: filteredSnowmobileSubPaths.map((subPath: SnowmobileSubPath) => subPath.id)});
                }
            }
            
            if (newFocus.length) {
                mapRef.current.setFocus(newFocus);
            }

            mapRef.current.setSelected(selectedType, selectedId);
        }
    }, [paths, pois, snowmobilePaths, snowmobileSubPaths, aois]);
    
    // Change selection if it's changed in the store
    useEffect(() => {
        mapRef.current.setSelected(selectedType, selectedId);
    }, [selectedType, selectedId]);

    // Change filters if it's changed in the store
    useEffect(() => {
        mapRef.current.setFilters(filters);
    }, [filters]);

    // Called when map is ready to receive commands
    const onLoad = useCallback(() => {
        mapRef.current.setMapType(MapType.Map);
    }, [mapRef]);

    // Called when map sends out a message
    const onMessage = (data: any) => {
        console.log(data);

        const {deselect, type, id} = data;
        if (deselect || (type !== undefined && id !== undefined)) {
            mapRef.current.setHighlighted(type, id);

            setSelected(type, id);
        }
    };

    // Called when user clicks the zoom buttons
    const onZoomIn = useCallback(() => mapRef.current.adjustZoom(1), [mapRef]);
    const onZoomOut = useCallback(() => mapRef.current.adjustZoom(-1), [mapRef]);

    const style = useThemedStyleFunction(mapPanelStyle);
    const containerStyle = withExternalStyle(style.container, styleProp);
    const deviceWindowWidth = Dimensions.get("window").width;
    const showLanguageSelector = deviceWindowWidth > SMALL_SCREEN_WIDTH && !selectedId && sideBarOpen;
    return (
        <View style={containerStyle}>
            <Map style={style.map} ref={(ref: any) => mapRef.current = ref} onLoad={onLoad} onMessage={onMessage}/>
            <LinearGradient colors={["#0000008F", "#00000000"]} style={style.gradient} >
                {showLanguageSelector &&
                    <LanguageSelector style={{marginTop: 10, alignItems: 'flex-end'}} showTitle={false} inverted={true}/>
                }
            </LinearGradient>
            <View style={style.zoom}>
                <TouchableOpacity style={style.zoomInButton} onPress={onZoomIn}>
                    <ThemedText style={style.zoomButtonText}>
                        +
                    </ThemedText>
                </TouchableOpacity>
                <TouchableOpacity style={style.zoomOutButton} onPress={onZoomOut}>
                    <ThemedText style={style.zoomButtonText}>
                        -
                    </ThemedText>
                </TouchableOpacity>
            </View>
            { !(pois || paths || snowmobileSubPaths || aois) && 
                <View style={style.loadingOverlay}>
                    <LoadingSpinner style={style.spinner}/>
                </View>
            }
        </View>
    );
};

export { MapPanel };