import { colors } from '@kullaberg/shared/src/utils/variables';

const defaultColor = colors.neutralColor500;

const COLORS = {
    PATHS: {
        CROSS_COUNTRY_SKIING: defaultColor,
        SNOWMOBILING: defaultColor,
        MOUNTAIN_BIKING: defaultColor,
        HIKING: defaultColor,
        ENDCAP: defaultColor,
        NO_CATEGORY: defaultColor,
    },
    POIS: {
        NO_CATEGORY: defaultColor,
        RESTAURANT: defaultColor,
        CAFES: defaultColor,
        ATTRACTIONS: defaultColor,
        LOOKOUTS: defaultColor,
        PARKING: defaultColor,
        CABINS: defaultColor,
        SKI_BUS: defaultColor,
        STORES: defaultColor,
        TOILETS: defaultColor,
        WARNING: colors.errorColor500,
        SHELTER: defaultColor,
    },
    AOIS: {
        WARNING_AREA: colors.warningAreaColor,
        IMPORTANT_AREA: colors.importantAreaColor,
        INFO_AREA: colors.areaColor
    }
};

export { COLORS };