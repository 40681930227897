import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Map, View, MapBrowserEvent, Feature, MapEvent } from 'ol';
import { Geometry, Point } from 'ol/geom';
import { Tile as TileLayer, VectorImage} from 'ol/layer';
import { Vector as VectorSource, XYZ, OSM } from 'ol/source';
import { fromLonLat, toLonLat, transform } from 'ol/proj';
import { getDistance } from 'ol/sphere';
import RenderFeature from 'ol/render/Feature';
import {createEmpty, extend, isEmpty} from 'ol/extent';
import { FitOptions } from 'ol/View';
import { isEqual } from 'lodash';
import {
    getRenderScale,
    createEmptyVectorSource,
    createEmptyVectorLayer,
    createIconClusterLayer,
    createSnowmobilingIconFeature,
    createCommonPathFeatures,
    createCommonPathIconFeature,
    createEndcapIconFeature,
    createCommonPoiIconFeature,
    createCommonAoiIconFeature,
    createSnowmobilePathFeatures,
    createGpsIndicatorFeature,
    postMessage,
    createCommonAoiFeatures
} from './utils';
import { ICONS, OBJECT_TYPE, OBJECT_ID, SD_MAP_KEY, HD_MAP_KEY, PROPS, COLORS } from './constants';
import { FeatureType, MapType, PathCategoryId, PathDirection, PathStatus } from './enums';
import {
    MapCategories,
    MapCategoryProps,
    FeatureGroup,
} from './interfaces';
import {
    PathGpsCoordinate,
    Path,
    Paths,
    Poi,
    Pois,
    SnowmobilePaths,
    SnowmobileSubPath,
    SnowmobileSubPaths,
    GpsCoordinate,
    PathGpsCoordinates,
    PoiCategories,
    PathCategories,
    Aois,
    AoiCategories,
    AoiGpsCoordinates,
    AoiGpsCoordinate,
    Aoi
} from '@kullaberg/shared/src/interfaces';
import { getTranslatedProperty } from "../src/utils"
import './Map.css';

type MapProps = {
    style?: any;
    onMessage?: (msg: string) => void;
    onNearestFeature?: (data: any) => void;
};

const MapComponent = (props: MapProps, ref: any) => {
    const {onMessage} = props;

    // Map
    const map = useRef<Map>();
    const mapRef = useRef<HTMLDivElement>(null);
    const previousMapZoom = useRef<number>();
    // Tile layers
    const sdMapLayer = useRef<TileLayer<XYZ>>();
    const hdMapLayer = useRef<TileLayer<OSM>>();
    const sateliteLayer = useRef<TileLayer<XYZ>>();
    // Tile sources
    const sdMapSource = useRef<XYZ>();
    const hdMapSource = useRef<OSM>();
    // Vector layers
    const snowmobilePathLayer = useRef<VectorImage<Feature<Geometry>>>();
    const highlightedSnowmobilePathLayer = useRef<VectorImage<Feature<Geometry>>>();
    const pathLayer = useRef<VectorImage<Feature<Geometry>>>();
    const aoiLayer = useRef<VectorImage<Feature<Geometry>>>();
    const highlightedAoiLayer = useRef<VectorImage<Feature<Geometry>>>();
    const highlightedPathLayer = useRef<VectorImage<Feature<Geometry>>>();
    const poiLayer = useRef<VectorImage<Feature<Geometry>>>();
    const highlightedPoiLayer = useRef<VectorImage<Feature<Geometry>>>();
    const gpsPositionLayer = useRef<VectorImage<Feature<Geometry>>>();
    const snowmobileIconLayer = useRef<VectorImage<Feature<Geometry>>>();
    const hikingIconLayer = useRef<VectorImage<Feature<Geometry>>>();
    const crossCountrySkiingIconLayer = useRef<VectorImage<Feature<Geometry>>>();
    const mountainBikingIconLayer = useRef<VectorImage<Feature<Geometry>>>();
    // Vector sources
    const aoiSource = useRef<VectorSource<Feature<Geometry>>>();
    const highlightedAoiSource = useRef<VectorSource<Feature<Geometry>>>();
    const snowmobilePathSource = useRef<VectorSource<Feature<Geometry>>>();
    const highlightedSnowmobilePathSource = useRef<VectorSource<Feature<Geometry>>>();
    const pathSource = useRef<VectorSource<Feature<Geometry>>>();
    const highlightedPathSource = useRef<VectorSource<Feature<Geometry>>>();
    const poiSource = useRef<VectorSource<Feature<Geometry>>>();
    const highlightedPoiSource = useRef<VectorSource<Feature<Geometry>>>();
    const gpsPositionSource = useRef<VectorSource<Feature<Geometry>>>();
    const snowmobileIconSource = useRef<VectorSource<Feature<Geometry>>>();
    const hikingIconSource = useRef<VectorSource<Feature<Geometry>>>();
    const crossCountrySkiingIconSource = useRef<VectorSource<Feature<Geometry>>>();
    const mountainBikingIconSource = useRef<VectorSource<Feature<Geometry>>>();
    // Data set by calling exposed functions
    const poiCategoryData = useRef<PoiCategories>({});
    const poiData = useRef<Pois>({});
    const aoiCategoryData = useRef<AoiCategories>({});
    const aoiData = useRef<Aois>({});
    const aoiGpsData = useRef<AoiGpsCoordinates>({});
    const pathCategoryData = useRef<PathCategories>({});
    const pathData = useRef<Paths>({});
    const snowmobilePathData = useRef<SnowmobilePaths>({});
    const snowmobileSubPathData = useRef<SnowmobileSubPaths>({});
    const pathGpsData = useRef<PathGpsCoordinates>({});
    const mapType = useRef<MapType>();
    const filterData = useRef<FeatureGroup[]>();
    const focusData = useRef<FeatureGroup[]>();
    const selectedType = useRef<string | undefined>();
    const selectedId = useRef<string | undefined>();
    const highlightedType = useRef<string | undefined>();
    const highlightedId = useRef<string | undefined>();
    const gpsPositionData = useRef<GpsCoordinate>();
    const gpsIsCentered = useRef<boolean>();
    const headingData = useRef<number>();
    const pathDirection = useRef<PathDirection | undefined>();
    // Data set by internal functions
    const pathCategoryProps = useRef<MapCategoryProps>();
    const poiCategoryProps = useRef<MapCategoryProps>();
    const aoiCategoryProps = useRef<MapCategoryProps>();
    
    /**
     * Exposed function to set the data for POIs
     */
    const setPois = (pois: Pois, categories: PoiCategories) => {
        if (!isEqual(poiData.current, pois) || !isEqual(poiCategoryData.current, categories)) {
            if (categories) {
                setPoiCategoryProps(categories);
            }

            poiData.current = pois;
            poiCategoryData.current = categories;
        }
    };

    /**
     * Exposed function to set the data for aois
     */
    const setAois = (aois: Aois, categories: AoiCategories, coordinates: AoiGpsCoordinates) => {
        if (!isEqual(aoiData.current, aois) || !isEqual(aoiCategoryData.current, categories) || !isEqual(aoiGpsData.current, coordinates)) {
            if (categories) {
                setAoiCategoryProps(categories);
            }
            aoiData.current = aois;
            aoiCategoryData.current = categories;
            aoiGpsData.current = coordinates;
        }
    };

    /**
     * Exposed function to set the data for paths
     */
    const setPaths = (paths: Paths, categories: PathCategories, coordinates: PathGpsCoordinates) => {
        if (!isEqual(pathData.current, paths) || !isEqual(pathCategoryData.current, categories) || !isEqual(pathGpsData.current, coordinates)) {
            if (categories) {
                setPathCategoryProps(categories);
            }

            pathData.current = paths;
            pathCategoryData.current = categories;
            pathGpsData.current = coordinates;
        }
    };

    /**
     * Exposed function to set the data for snowmobile paths
     */
    const setSnowmobilePaths = (paths: SnowmobilePaths, subPaths: SnowmobileSubPaths) => {
        if (!isEqual(snowmobilePathData.current, paths) || !isEqual(snowmobileSubPathData.current, subPaths)) {
            snowmobilePathData.current = paths;
            snowmobileSubPathData.current = subPaths;
        }
    };

    /**
     * Exposed function to set the type of map
     */
    const setMapType = (type: MapType) => {
        if (mapType.current !== type) {
            updateMapSources(type, true);

            mapType.current = type;
        }
    };
    
    /**
     * Exposed function to filter the rendered features on the map
     */
    const setFilters = (filters: FeatureGroup[]) => {
        if (!isEqual(filterData.current, filters)) {
            filterData.current = filters;

            redraw();
            refocus();
        }
    };

    /**
     * Exposed function to focus on particular feature groups
     */
    const setFocus = (focus: FeatureGroup[], shouldRefocus: boolean = true) => {
        if (!isEqual(focusData.current, focus)) {
            focusData.current = focus;

            if (shouldRefocus) {
                refocus();
            }
        }
    };

    /**
     * Exposed function to set the selected feature on the map (feature to highlight and refocus on)
     */
    const setSelected = (type?: string, id?: string) => {
        selectedType.current = type;
        selectedId.current = id;
        // Highlight differs, render new highlight and then refocus
        if (highlightedType.current !== type || highlightedId.current !== id) {
            setHighlighted(type, id);
            setTimeout(() => {
                refocus();
            });
        }
        // Highlight doesn't change, just refocus
        else {
            refocus();
        }
    };

    /**
     * Exposed function to set the highlighted feature on the map
     */
    const setHighlighted = (type?: string, id?: string) => {
        //set opacity 0.5 to all unselcted features
        if (highlightedType.current !== type || highlightedId.current !== id) {
            highlightedType.current = type;
            highlightedId.current = id;
            const defaultOpacity = type !== undefined || id !== undefined ? 0.5: 1;
            //path icons
            snowmobileIconLayer.current?.setOpacity(defaultOpacity);
            snowmobileIconLayer.current?.changed();
            hikingIconLayer.current?.setOpacity(defaultOpacity);
            hikingIconLayer.current?.changed();
            mountainBikingIconLayer.current?.setOpacity(defaultOpacity);
            mountainBikingIconLayer.current?.changed();
            crossCountrySkiingIconLayer.current?.setOpacity(defaultOpacity);
            crossCountrySkiingIconLayer.current?.changed(); 
            //paths layer
            pathLayer.current?.setOpacity(defaultOpacity);
            pathLayer.current?.changed();
            snowmobilePathLayer.current?.setOpacity(defaultOpacity);
            snowmobilePathLayer.current?.changed();
            //aois layer
            aoiLayer.current?.setOpacity(defaultOpacity);
            aoiLayer.current?.changed();
            //pois layer
            poiLayer.current?.setOpacity(defaultOpacity);
            poiLayer.current?.changed();

            redrawHighlight();
        }
    };

    /**
     * Exposed function to set the current GPS position and heading on the map
     */
    const setGpsPosition = (position?: GpsCoordinate, heading?: number) => {
        if (!isEqual(gpsPositionData.current, position) || !isEqual(headingData.current, heading)) {
            gpsPositionSource.current?.clear();
          
            if (position) {
                const {longitude, latitude} = position;
                const coordinate = fromLonLat([longitude, latitude]);
                const point = new Point(coordinate);
          
                gpsPositionSource.current?.addFeatures([createGpsIndicatorFeature(point, heading)]);
                reportNearestFeature(coordinate);
            }

            // Invalidate centered state if position has changed
            if (!isEqual(gpsPositionData.current, position)) {
                gpsIsCentered.current = false;
                const msg = JSON.stringify({centered: false});
                postMessage(msg);
                onMessage?.(msg);

                gpsPositionData.current = position;
            }
        }
    };

    /**
     * Exposed function to center the view on the current GPS position
     */
    const centerGps = () => {
        if (!gpsPositionSource.current?.getFeatures().length) {
            return;
        }
        const view = map.current?.getView();
        view?.fit(gpsPositionSource.current.getExtent());
        view?.setRotation(0);

        gpsIsCentered.current = true;

        const msg = JSON.stringify({centered: true});
        postMessage(msg);
        onMessage?.(msg);
    };

    /**
     * Exposed function to zoom in or out by a specified amount
     */
    const adjustZoom = (amount: number) => {
        const view = map.current?.getView();
        if (view === undefined) {
            return;
        }

        const zoom = view.getZoom();
        if (zoom === undefined) {
            return;
        }

        view.animate({
            zoom: zoom + amount,
            duration: 100
        });
    };

    /**
     * Exposed function to set the path direction indication
     */
    const setPathDirection = (direction?: PathDirection, redraw?: boolean) => {
        pathDirection.current = direction;

        if (redraw && highlightedType.current !== undefined && highlightedId.current !== undefined) {
            redrawHighlight();
        }
    };

    // Expose functions through the component reference
    useImperativeHandle(ref, () => ({
        setAois,
        setPois,
        setPaths,
        setSnowmobilePaths,
        setMapType,
        setFilters,
        setFocus,
        setSelected,
        setHighlighted,
        setGpsPosition,
        centerGps,
        adjustZoom,
        setPathDirection,
    }));

    // Expose functions through the window scope
    (window as any).setAois = setAois;
    (window as any).setPois = setPois;
    (window as any).setPaths = setPaths;
    (window as any).setSnowmobilePaths = setSnowmobilePaths;
    (window as any).setMapType = setMapType;
    (window as any).setFilters = setFilters;
    (window as any).setFocus = setFocus;
    (window as any).setSelected = setSelected;
    (window as any).setHighlighted = setHighlighted;
    (window as any).setGpsPosition = setGpsPosition;
    (window as any).centerGps = centerGps;
    (window as any).adjustZoom = adjustZoom;
    (window as any).setPathDirection = setPathDirection;

    // Initial setup
    useEffect(() => {
        // Create Standard Definition map source (lantmäteriet)
        sdMapSource.current = new XYZ ({
            maxZoom: 14,
        });
        sdMapSource.current.setUrl(
            `https://api.lantmateriet.se/open/topowebb-ccby/v1/wmts/token/${SD_MAP_KEY}/?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=topowebb&STYLE=default&TILEMATRIXSET=3857&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=image/png`,
        );

        // Create Standard Definition map layer
        sdMapLayer.current = new TileLayer({
            source: sdMapSource.current,
            opacity: 0.7,
        });
        sdMapLayer.current?.setVisible(false);

        // Create High Definition map source (thunderforest)
        hdMapSource.current = new OSM({
            //
        });
        hdMapSource.current.setUrls([
            `https://a.tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=${HD_MAP_KEY}`,
            `https://b.tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=${HD_MAP_KEY}`,
            `https://c.tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=${HD_MAP_KEY}`,
        ]);

        // Create High Definition map layer
        hdMapLayer.current = new TileLayer({
            source: hdMapSource.current,
            opacity: 0.7,
        });
        hdMapLayer.current?.setVisible(false);
        
        // Create a layer for the satelite map
        sateliteLayer.current = new TileLayer({
            source: new XYZ({
                attributions: [
                    'Powered by Esri',
                    'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community',
                ],
                attributionsCollapsible: false,
                url: `https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`,
                maxZoom: 17,
            }),
            opacity: 0.7,
        });
        sateliteLayer.current?.setVisible(false);

        // Create a new layers to render snowmobile paths on
        snowmobilePathSource.current = createEmptyVectorSource();
        snowmobilePathLayer.current = createEmptyVectorLayer(snowmobilePathSource.current);
        highlightedSnowmobilePathSource.current = createEmptyVectorSource();
        highlightedSnowmobilePathLayer.current = createEmptyVectorLayer(highlightedSnowmobilePathSource.current);

        // Create a new layers to render paths on
        pathSource.current = createEmptyVectorSource();
        pathLayer.current = createEmptyVectorLayer(pathSource.current);
        highlightedPathSource.current = createEmptyVectorSource();
        highlightedPathLayer.current = createEmptyVectorLayer(highlightedPathSource.current);

        // Create a new layers to render aois on
        aoiSource.current = createEmptyVectorSource();
        aoiLayer.current = createEmptyVectorLayer(aoiSource.current);
        highlightedAoiSource.current = createEmptyVectorSource();
        highlightedAoiLayer.current = createEmptyVectorLayer(highlightedAoiSource.current);

        // Create a new layers to render pois on
        poiSource.current = createEmptyVectorSource();
        poiLayer.current = createEmptyVectorLayer(poiSource.current);
        highlightedPoiSource.current = createEmptyVectorSource();
        highlightedPoiLayer.current = createEmptyVectorLayer(highlightedPoiSource.current);

        // Create a new layer to render GPS poisition on
        gpsPositionSource.current = createEmptyVectorSource();
        gpsPositionLayer.current = createEmptyVectorLayer(gpsPositionSource.current);

        // Create a new layer to render snowmobile icon cluster on
        snowmobileIconSource.current = createEmptyVectorSource();
        snowmobileIconLayer.current = createIconClusterLayer(
            snowmobileIconSource.current,
            ICONS.PATHS.SNOWMOBILING,
            COLORS.PATHS.SNOWMOBILING,
        );

        // Create a new layer to render hiking icon cluster on
        hikingIconSource.current = createEmptyVectorSource();
        hikingIconLayer.current = createIconClusterLayer(
            hikingIconSource.current,
            ICONS.PATHS.HIKING,
            COLORS.PATHS.HIKING,
        );

        // Create a new layer to render cross country skiing icon cluster on
        crossCountrySkiingIconSource.current = createEmptyVectorSource();
        crossCountrySkiingIconLayer.current = createIconClusterLayer(
            crossCountrySkiingIconSource.current,
            ICONS.PATHS.CROSS_COUNTRY_SKIING,
            COLORS.PATHS.CROSS_COUNTRY_SKIING,
        );

        // Create a new layer to render mountain biking icon cluster on
        mountainBikingIconSource.current = createEmptyVectorSource();
        mountainBikingIconLayer.current = createIconClusterLayer(
            mountainBikingIconSource.current,
            ICONS.PATHS.MOUNTAIN_BIKING,
            COLORS.PATHS.MOUNTAIN_BIKING,
        );

        // Create the OL Map
        map.current = new Map({
            target: mapRef.current!,
            layers: [
                sdMapLayer.current,
                hdMapLayer.current,
                sateliteLayer.current,
                snowmobilePathLayer.current,
                pathLayer.current,
                aoiLayer.current,
                snowmobileIconLayer.current,
                hikingIconLayer.current,
                mountainBikingIconLayer.current,
                crossCountrySkiingIconLayer.current,
                highlightedSnowmobilePathLayer.current,
                highlightedPathLayer.current,
                highlightedAoiLayer.current,
                poiLayer.current,
                highlightedPoiLayer.current,
                gpsPositionLayer.current,
            ],
            view: new View({
                center: fromLonLat([
                    12.545782718807459,
                    62.545728189870715
                ]),
                zoom: 6,
                minZoom: 6,
                maxZoom: 16,
            }),
            ...(process.env.REACT_APP_STANDALONE !== 'true' && {controls: []}),
        });

        // Register OL Map events
        map.current.on('singleclick', (event) => handleSingleClick(event));
        map.current.on('pointerdrag', (event) => handlePointerDrag(event));
        map.current.on('moveend', (event) => handleMoveEnd(event));
    }, []);

    /**
     * Updates map sources based on map type
     */
    const updateMapSources = (type?: MapType, force?: boolean) => {
        switch (type) {
            case MapType.Map:
                sateliteLayer.current?.setVisible(false);

                // Check if zoom differs since last udpate
                const zoom = map.current?.getView().getZoom() ?? 0;
                if (zoom !== previousMapZoom.current || force) {
                    const useHd = zoom > 14;
                    sdMapLayer.current?.setVisible(!useHd);
                    hdMapLayer.current?.setVisible(useHd);
        
                    previousMapZoom.current = zoom;
                }
                break;

            case MapType.Satelite:
                sdMapLayer.current?.setVisible(false);
                hdMapLayer.current?.setVisible(false);
                sateliteLayer.current?.setVisible(true);
                break;

            default:
                break;
        }
    };

    /**
     * Handles single click events within the map
     */
     const handleSingleClick = (event: MapBrowserEvent<any>) => {
        let hitFeature:  undefined | RenderFeature | Feature<Geometry>;

        // Itterate each point and see if we hit any feature
        map.current?.forEachFeatureAtPixel(
            event.pixel,
            (rootFeature) => {
                const features = [rootFeature, ...(rootFeature.get('features') ?? [])];
                features.forEach((feature) => {
                    const type = feature.get(OBJECT_TYPE);
                    const id = feature.get(OBJECT_ID);
    
                    const isSelectable = type && id;
                    const hasPriority = !hitFeature || type === FeatureType.Poi;
    
                    if (isSelectable && hasPriority) {
                        hitFeature = feature;
                    }
                });
            },
            {
                hitTolerance: 11 * getRenderScale(),
            },
        );

        // Check if we have data within the detected feature, early return if not
        if (!hitFeature) {
            const msg = JSON.stringify({deselect: true});
            postMessage(msg);
            onMessage?.(msg);
            return;
        }

        const type = hitFeature?.get(OBJECT_TYPE);
        const id = hitFeature?.get(OBJECT_ID);

        const msg = JSON.stringify({type, id});
        postMessage(msg);
        onMessage?.(msg);
    };

    /**
     * Handles drag events within the map
     */
    const handlePointerDrag = (event: MapBrowserEvent<any>) => {
        if (gpsIsCentered.current) {
            const msg = JSON.stringify({centered: false});
            postMessage(msg);
            onMessage?.(msg);

            gpsIsCentered.current = false;
        }
    };

    /**
     * Handles move end events within the map
     */
    const handleMoveEnd = (event: MapEvent) => {
        updateMapSources(mapType.current);
    };

    /**
     * Sets up a map of the properties associated with each PoI category
     */
    const setPoiCategoryProps = (poiCategories: MapCategories) => {
        poiCategoryProps.current = {};
        for(const [key, value] of Object.entries(poiCategories)) {
            // Save Identifier data for this poi id
            poiCategoryProps.current[key] = (PROPS.POIS as any)[value.identifier];
        }
    };

    /**
     * Sets up a map of the properties associated with each Path category
     */
    const setPathCategoryProps = (pathCategories: MapCategories) => {
        pathCategoryProps.current = {};
        for(const [key, value] of Object.entries(pathCategories)) {
            // Save Identifier data for this path id
            pathCategoryProps.current[key] = (PROPS.PATHS as any)[value.identifier];
        }
    };

    /**
     * Sets up a map of the properties associated with each Path category
     */
    const setAoiCategoryProps = (aoiCategories: MapCategories) => {
        aoiCategoryProps.current = {};
        for(const [key, value] of Object.entries(aoiCategories)) {
            // Save Identifier data for this aoi id
            aoiCategoryProps.current[key] = (PROPS.AOIS as any)[value.identifier];
        }
    };

    /**
     * Redraws all the map sources given the current filters, selection, etc.
     */
    const redraw = () => {
        aoiSource.current?.clear();
        pathSource.current?.clear();
        snowmobilePathSource.current?.clear();
        poiSource.current?.clear();
        snowmobileIconSource.current?.clear();
        hikingIconSource.current?.clear();
        crossCountrySkiingIconSource.current?.clear();
        mountainBikingIconSource.current?.clear();
        
        if (!filterData.current?.length) {
            drawPaths([]);
            drawSnowmobilePaths([]);
            drawPois([]);
            drawAois([]);
        } else {
            filterData.current.forEach((mapFilter: FeatureGroup) => {
                switch (mapFilter.type) {
                    case FeatureType.Path:
                        drawPaths(mapFilter.ids);
                        break;
            
                    case FeatureType.SnowmobilePath:
                        drawSnowmobilePaths(mapFilter.ids);
                        break;

                    case FeatureType.SnowmobileSubPath:
                        drawSnowmobileSubPaths(mapFilter.ids);
                        break;
            
                    case FeatureType.Poi:
                        drawPois(mapFilter.ids);
                        break;
                    
                    case FeatureType.Aoi:
                        drawAois(mapFilter.ids);
                        break;
            
                    default:
                        break;
                }
            });
        }

        if (selectedType.current !== undefined && selectedId.current !== undefined) {
            redrawHighlight();
        }
    };

    /**
     * Draws all the paths provided in pathData, filter with optional ID array
     */
    const drawPaths = (ids?: string[]) => {
        if (!pathData.current || !Object.keys(pathData.current).length) {
            return;
        }

        const crossCountrySkiingLineFeatures: Feature<Geometry>[] = [];
        const crossCountrySkiingIconFeatures: Feature<Geometry>[] = [];
        const crossCountrySkiingEndcapFeatures: Feature<Geometry>[] = [];
      
        const mountainBikingLineFeatures: Feature<Geometry>[] = [];
        const mountainBikingIconFeatures: Feature<Geometry>[] = [];
        const mountainBikingEndcapFeatures: Feature<Geometry>[] = [];
      
        const hikingLineFeatures: Feature<Geometry>[] = [];
        const hikingIconFeatures: Feature<Geometry>[] = [];
        const hikingEndcapFeatures: Feature<Geometry>[] = [];

        Object.values(pathData.current).forEach((path) => {
            if (!ids?.length || ids.includes(path.id)) {

                switch(path.pathCategoryId) {
                    case PathCategoryId.CrossCountrySkiing:
                        drawPath(
                            crossCountrySkiingLineFeatures,
                            crossCountrySkiingIconFeatures,
                            crossCountrySkiingEndcapFeatures,
                            path,
                        );
                        break;

                    case PathCategoryId.MountainBiking:
                        drawPath(
                            mountainBikingLineFeatures,
                            mountainBikingIconFeatures,
                            mountainBikingEndcapFeatures,
                            path,
                        );
                        break;

                    case PathCategoryId.Hiking:
                        drawPath(
                            hikingLineFeatures,
                            hikingIconFeatures,
                            hikingEndcapFeatures,
                            path,
                        );
                        break;

                    default:
                        break;
                }
            }
        });

        // Add all the features for cross country skiing
        pathSource.current?.addFeatures([
          ...crossCountrySkiingLineFeatures,
          ...crossCountrySkiingEndcapFeatures,
        ]);
        crossCountrySkiingIconSource.current?.addFeatures([...crossCountrySkiingIconFeatures]);
        // Add all the features for mountain biking
        pathSource.current?.addFeatures([
          ...mountainBikingLineFeatures,
          ...mountainBikingEndcapFeatures,
        ]);
        mountainBikingIconSource.current?.addFeatures([...mountainBikingIconFeatures]);
        // Add all the features for hiking
        pathSource.current?.addFeatures([...hikingLineFeatures, ...hikingEndcapFeatures]);
        hikingIconSource.current?.addFeatures([...hikingIconFeatures]);
    }

    /**
     * Draws the required features for a path to the provided feature lists
     */
    const drawPath = (
        lineFeatures: Feature<Geometry>[],
        iconFeatures: Feature<Geometry>[],
        endcapFeatures: Feature<Geometry>[],
        path: Path,
        isBold: boolean = false,
        direction?: PathDirection,
    ) => {
        if (!pathCategoryProps.current) {
            return;
        }

        if (!pathGpsData.current || !pathGpsData.current[path.id]) {
            return;
        }

        const {color, icon, closedIcon} = pathCategoryProps.current[path.pathCategoryId];
        const lineCoordinates = pathGpsData.current[path.id].map((coordinate: PathGpsCoordinate, index: number) =>
            fromLonLat([coordinate.longitude, coordinate.latitude, index]),
        );
        const pathStatus = getTranslatedProperty('status', path)
        lineFeatures.push(
            ...createCommonPathFeatures(
                lineCoordinates,
                path.id,
                color,
                pathStatus === PathStatus.Closed,
                isBold,
                direction,
            ),
        );

        const endIndex = lineCoordinates.length - 1;
        const midIndex = Math.floor(endIndex / 2);
      
        const hasDarkBackground = mapType.current === MapType.Satelite;
        const startName = getTranslatedProperty('start', path)
        const endName = getTranslatedProperty('end', path)
        endcapFeatures.push(createEndcapIconFeature(new Point(lineCoordinates[0]), startName, hasDarkBackground));
        endcapFeatures.push(createEndcapIconFeature(new Point(lineCoordinates[endIndex]), endName, hasDarkBackground));
        
        const iconUrl = pathStatus !== PathStatus.Closed ? icon : closedIcon;
        const hasNumber = path.number !== undefined && path.number.length && path.number !== " ";
        const pathName = getTranslatedProperty('name', path)
        iconFeatures.push(
            createCommonPathIconFeature(
                new Point(lineCoordinates[midIndex]),
                path.id,
                hasNumber ? path.number : pathName,
                color,
                iconUrl,
                isBold,
            ),
        );
    };

    /**
     * Draws all the paths provided in snowmobilePathData, filter with optional ID array
     */
    const drawSnowmobilePaths = (ids?: string[]) => {
        const lineFeatures: Feature<Geometry>[] = [];
        const iconFeatures: Feature<Geometry>[] = [];

        if (ids?.length) {
            ids.forEach((id) => {
                const path = snowmobilePathData.current[id];
        
                path.subPaths.forEach((subPath) => {
                    if (typeof subPath === 'string') {
                        drawSnowmobileSubPath(lineFeatures, iconFeatures, snowmobileSubPathData.current[subPath]);
                    }
                    else {
                        drawSnowmobileSubPath(lineFeatures, iconFeatures, subPath);
                    }
                });
            });
        } else {
            Object.values(snowmobileSubPathData.current).forEach((subPath) => {
                drawSnowmobileSubPath(lineFeatures, iconFeatures, subPath);
            });
        }
      
        snowmobilePathSource.current?.addFeatures([...lineFeatures, ...iconFeatures]);
        snowmobileIconSource.current?.addFeatures([]); // NOTE: Empty at the moment as many paths icons for crossings overlap, resulting in always being clustered
    };

    /**
     * Draws all the subpaths provided in snowmobileSubPathData, filter with optional ID array
     */
    const drawSnowmobileSubPaths = (ids?: string[]) => {
        const lineFeatures: Feature<Geometry>[] = [];
        const iconFeatures: Feature<Geometry>[] = [];

        if (ids?.length) {
            Object.values(snowmobileSubPathData.current).forEach((subPath) => {
                if (ids.includes(subPath.id)) {
                    drawSnowmobileSubPath(lineFeatures, iconFeatures, subPath);
                }
            });
        }
      
        snowmobilePathSource.current?.addFeatures([...lineFeatures, ...iconFeatures]);
        snowmobileIconSource.current?.addFeatures([]); // NOTE: Empty at the moment as many paths icons for crossings overlap, resulting in always being clustered
    };

    /**
     * Draws the required features for a snowmobile subpath to the provided feature lists
     */
    const drawSnowmobileSubPath = (
        lineFeatures: Feature<Geometry>[],
        iconFeatures: Feature<Geometry>[],
        subPath: SnowmobileSubPath,
        isBold: boolean = false,
    ) => {
        const lineCoordinates = subPath.subPathGPSCoordinates.map((coordinate) =>
            fromLonLat([coordinate.longitude, coordinate.latitude]),
        );
      
        lineFeatures.push(
            ...createSnowmobilePathFeatures(
                lineCoordinates,
                subPath.id,
                subPath.status === PathStatus.Closed,
                isBold,
            ),
        );
      
        if (subPath.start) {
            iconFeatures.push(
                createSnowmobilingIconFeature(new Point(lineCoordinates[0]), subPath.start, isBold),
            );
        }
      
        if (subPath.end) {
            const endIndex = lineCoordinates.length - 1;
            iconFeatures.push(
                createSnowmobilingIconFeature(new Point(lineCoordinates[endIndex]), subPath.end, isBold),
            );
        }
    };

    /**
     * Draws all the POIs provided in poiData, filter with optional ID array
     */
    const drawPois = (ids?: string[]) => {
        const iconFeatures: Feature<Geometry>[] = [];

        Object.values(poiData.current).forEach((poi) => {
            if (!ids?.length || ids.includes(poi.id)) {
                drawPoi(iconFeatures, poi);
            }
        });
        
        poiSource.current?.addFeatures([...iconFeatures]);
    };

    /**
     * Draws the required features for a POI to the provideed feature lists
     */
    const drawPoi = (iconFeatures: Feature<Geometry>[], poi: Poi, isBold: boolean = false) => {
        if (!poiCategoryProps.current) {
            return;
        }
        const {color, icon} = poiCategoryProps.current[poi.poiCategoryId];
        const coordinates = fromLonLat([poi.longitude, poi.latitude]);
      
        const hasDarkBackground = mapType.current === MapType.Satelite;
        const name = getTranslatedProperty('name', poi)
        iconFeatures.push(
            createCommonPoiIconFeature(new Point(coordinates), poi.id, name, color, icon, hasDarkBackground, isBold),
        );
    };

    /**
     * Draws all the AOIs provided in aoiData, filter with optional ID array
     */
    const drawAois = (ids?: string[]) => {
        const iconFeatures: Feature<Geometry>[] = [];
        const lineFeatures: Feature<Geometry>[] = [];    

        Object.values(aoiData.current).forEach((aoi) => {
            if (!ids?.length || ids.includes(aoi.id)) {
                drawAoi(lineFeatures, iconFeatures, aoi);
            }
        });
        
        aoiSource.current?.addFeatures([...iconFeatures]);
    };

    /**
     * Draws the required features for a aoi to the provided feature lists
     */
    const drawAoi = (
        lineFeatures: Feature<Geometry>[],
        iconFeatures: Feature<Geometry>[],
        aoi: Aoi,
        isBold: boolean = false,
    ) => {

        if (!aoiCategoryProps.current) {
            return;
        }

        if (!aoiGpsData.current || !aoiGpsData.current[aoi.id]) {
            return;
        }

        const {color, icon} = aoiCategoryProps.current[aoi.aoiCategoryId];
        const lineCoordinates = aoiGpsData.current[aoi.id].map((coordinate: AoiGpsCoordinate, index: number) =>
            fromLonLat([coordinate.longitude, coordinate.latitude, index]),
        );
     
        lineFeatures.push(
            ...createCommonAoiFeatures(
                lineCoordinates,
                aoi.id,
                color,
            ),
        );

        const xCoordinates = lineCoordinates.map((coordinate) => coordinate[0]);
        xCoordinates.sort((a, b) => a - b);
        const yCoordinates = lineCoordinates.map((coordinate) => coordinate[1]);
        yCoordinates.sort((a, b) => a - b);
        const center = [
          (xCoordinates[0] + xCoordinates[xCoordinates.length - 1]) / 2,
          (yCoordinates[yCoordinates.length - 1] + yCoordinates[0]) / 2,
        ];

        const hasDarkBackground = mapType.current === MapType.Satelite;
        const name = getTranslatedProperty('name', aoi);
        iconFeatures.push(
            createCommonAoiIconFeature(new Point(center), aoi.id, name, color, icon, hasDarkBackground, isBold),
        );
    };

    /**
     * Draws the required features for the current selection to the highlight source
     */
    const redrawHighlight = () => {
        const lineFeatures: Feature<Geometry>[] = [];
        const iconFeatures: Feature<Geometry>[] = [];

        highlightedPathSource.current?.clear();
        highlightedSnowmobilePathSource.current?.clear();
        highlightedPoiSource.current?.clear();
        highlightedAoiSource.current?.clear();

        highlightedPathLayer.current?.setVisible(false);
        highlightedSnowmobilePathLayer.current?.setVisible(false);
        highlightedPoiLayer.current?.setVisible(false);
        highlightedAoiLayer.current?.setVisible(false);
        
        if (!highlightedId.current) {
            return;
        }
      
        switch (highlightedType.current) {
            case FeatureType.Path:
                const path = pathData.current[`${highlightedId.current}`];
                drawPath(lineFeatures, iconFeatures, iconFeatures, path, true, pathDirection.current);
                highlightedPathSource.current?.addFeatures([...lineFeatures, ...iconFeatures]);
                highlightedPathLayer.current?.setVisible(true);
                break;
        
            case FeatureType.SnowmobilePath:
                const snowmobilePath = snowmobilePathData.current[`${highlightedId.current}`];
                snowmobilePath.subPaths.forEach((subPath) => {
                    if (typeof subPath === 'string') {
                        drawSnowmobileSubPath(lineFeatures, iconFeatures, snowmobileSubPathData.current[subPath], true);
                    }
                    else {
                        drawSnowmobileSubPath(lineFeatures, iconFeatures, subPath);
                    }
                });
                highlightedSnowmobilePathSource.current?.addFeatures([...lineFeatures, ...iconFeatures]);
                highlightedSnowmobilePathLayer.current?.setVisible(true);
                break;
        
            case FeatureType.SnowmobileSubPath:
                const snowmobileSubPath = snowmobileSubPathData.current[highlightedId.current];
                drawSnowmobileSubPath(
                    lineFeatures,
                    iconFeatures,
                    snowmobileSubPath,
                    true,
                );
                highlightedSnowmobilePathSource.current?.addFeatures([...lineFeatures, ...iconFeatures]);
                highlightedSnowmobilePathLayer.current?.setVisible(true);
                break;
        
            case FeatureType.Poi:
                const poi = poiData.current[`${highlightedId.current}`];
                drawPoi(iconFeatures, poi, true);
                highlightedPoiSource.current?.addFeatures(iconFeatures);
                highlightedPoiLayer.current?.setVisible(true);
                break;

            case FeatureType.Aoi:
                const aoi = aoiData.current[`${highlightedId.current}`];
                drawAoi(lineFeatures, iconFeatures, aoi, true);
                highlightedAoiSource.current?.addFeatures([...lineFeatures, ...iconFeatures]);
                highlightedAoiLayer.current?.setVisible(true);
                break;
        
            default:
                break;
        }
    };

    /**
     * Recenters the selected feature, recenters all content if no selection is provided
     */
    const refocus = () => {
        const source = getSourceForType(selectedType.current);
    
        const options: FitOptions = {
            padding: [50, 50, 50, 50],
        };

        if (source?.getFeatures().length) {
            if (selectedId.current !== undefined) {
                let extent = createEmpty();
        
                if (selectedType.current === FeatureType.SnowmobilePath) {
                    const subPathIds = snowmobilePathData.current[selectedId.current]?.subPaths;
            
                    if (subPathIds?.length) {
                        source.getFeatures().forEach((feature) => {
                            subPathIds.forEach((subPathId) => {
                                const type = feature.get(OBJECT_TYPE);
                                const id = feature.get(OBJECT_ID);

                                if (type === FeatureType.SnowmobileSubPath && id === subPathId) {
                                    extend(extent, feature!.getGeometry()!.getExtent());
                                }
                            });
                        });
                    }
                } else {
                    source.getFeatures().forEach((feature) => {
                        const type = feature.get(OBJECT_TYPE);
                        const id = feature.get(OBJECT_ID);
                    
                        if (type === selectedType.current && id === selectedId.current) {
                            extend(extent, feature!.getGeometry()!.getExtent());
                        }
                    });
                }

                map.current?.getView().fit(extent, options);
            } else {
                const extent = source.getExtent();
                map.current?.getView().fit(extent, options);
            }
        } else {
            const extent = createEmpty();
            if (pathSource.current && snowmobilePathSource.current && poiSource.current && aoiSource.current) {
                if (focusData.current?.length) {
                    focusData.current.forEach((group) => {
                        const {type: groupType, ids} = group;
                        const groupSource = getSourceForType(groupType);

                        groupSource?.getFeatures().forEach((feature) => {
                            const type = feature.get(OBJECT_TYPE);
                            const id = feature.get(OBJECT_ID);
                        
                            if (type === groupType && ids.includes(id)) {
                                extend(extent, feature!.getGeometry()!.getExtent());
                            }
                        });
                    });
                }
                else {
                    extend(extent, pathSource.current.getExtent());
                    extend(extent, snowmobilePathSource.current.getExtent());
                    extend(extent, poiSource.current.getExtent());
                    extend(extent, aoiSource.current.getExtent());
                }
            }
            if (!isEmpty(extent)) {
                map.current?.getView().fit(extent, options);
            }
        }
    };

    /**
     * Gets the source for a given feature type
     */
    const getSourceForType = (type?: string): VectorSource<Feature<Geometry>> | undefined => {
        switch (type) {
            case FeatureType.Path:
                return pathSource.current;
        
            case FeatureType.SnowmobilePath:
            case FeatureType.SnowmobileSubPath:
                return snowmobilePathSource.current;
        
            case FeatureType.Poi:
                return poiSource.current;

            case FeatureType.Aoi:
                return aoiSource.current;
        
            default:
                return undefined;
        }
    };

    /**
     * Gets the distance between two coordinates
     */
    const getCoordsDistance = (coordinate1: number[], coordinate2: number[], projection?: string): number => {
        projection = projection || 'EPSG:4326';
    
        const sourceProj = map.current?.getView().getProjection();
        const c1 = transform(coordinate1, sourceProj, projection);
        const c2 = transform(coordinate2, sourceProj, projection);
    
        return getDistance(c1, c2);
    };

    /**
     * Posts a message about the feature nearest to a GPS coordinate
     */
    const reportNearestFeature = (coordinate: number[]) => {
        const closestFeature = pathSource.current?.getClosestFeatureToCoordinate(coordinate);

        if (closestFeature) {
            const closestPoint = closestFeature!
                .getGeometry()!
                .getClosestPoint(coordinate);

            if (getCoordsDistance(closestPoint, coordinate) > 70) {
                return;
            }

            const objectId = closestFeature.get(OBJECT_ID);
            if (!objectId) {
                return;
            }

            const latLongCoords = toLonLat(closestPoint);
            const msg = JSON.stringify({
                pathId: parseInt(objectId, 10),
                pointCoordinate: {
                    x: latLongCoords[0],
                    y: latLongCoords[1],
                    ...(closestPoint[2] && {index: closestPoint[2]}),
                },
            });
            postMessage(msg);
            onMessage?.(msg);
        } else {
            const msg = JSON.stringify({
                pathId: 0,
                pointCoordinate: {
                    x: 0,
                    y: 0,
                },
            });
            postMessage(msg);
            onMessage?.(msg);
        }
    };

    return (
        <div ref={mapRef} className="map"></div>
    )
};

export default forwardRef(MapComponent);